<template>
    <div class="modal-notice">
        <div class="head">
            <div class="flex-row flex-between items-start">
                <div class="left flex-fill">
                    <div class="badges flex-row">
                        <div
                            v-if="notice.is_required"
                            class="badge badge-brd red flex-wrap"
                            v-html="$translate('NOTICE_REQUIRED')"
                        />
                        <div
                            v-if="notice.is_new"
                            class="badge badge-brd primary flex-wrap"
                            :class="{'m-l-4': notice.is_required}"
                            v-html="'NEW'"
                        />
                    </div>
                    <div class="title" v-html="$translate(notice.title)"/>
                </div>
                <i @click="$emit('close')" class="zmdi zmdi-close"/>
            </div>
            <div class="timestamp">{{ notice.created_at | formatDate('YY.MM.DD') }}</div>
        </div>
        <div class="hr"/>
        <div class="body" v-html="notice.description"/>
    </div>
</template>
<script>
export default {
    name: 'ModalNotice',
    props: ['options'],
    computed: {
        notice() {
            return (this.options || {}).notice || {}
        },
    },
}
</script>